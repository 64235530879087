import classes from "./Team.module.css";
import TeamMember from "./TeamMember";
import React from "react";
import Srdjan from "../../assets/team/srdjan.png";
import Dubravko from "../../assets/team/Dubravko.png";
import Kresimir from "../../assets/team/Kresimir.png";
import Simikic from "../../assets/team/Simikic.png";
import Damjanov from "../../assets/team/Damjanov.png";
import Andrija from "../../assets/team/andrija.png";
import Dejan from "../../assets/team/Dejan Vukmirovic.png";
import Miljko from "../../assets/team/Miljko.png";
import Blagoje from "../../assets/team/Blagoje.png";
import Viktorija from "../../assets/team/Viktorija.png";
import Ana from "../../assets/team/Ana.png";
import Visnja from "../../assets/team/Visnja.png";

const Team = () => {
  const team = [
    {
      id: 1,
      name: "Srdjan Vukmirovic",
      position: "Founder",
      linkedinUrl: "https://www.linkedin.com/in/srdjan-vukmirovic-a8b2632a/",
      imageSrc: {
        default: Srdjan,
      },
    },
    {
      id: 2,
      name: "Dubravko Culibrk",
      position: "Founder",
      linkedinUrl: "https://www.linkedin.com/in/dubravko-culibrk-44b8105/",
      imageSrc: {
        default: Dubravko,
      },
    },

    {
      id: 3,
      name: "Kresimir Kristo",
      position: "CEO",
      linkedinUrl:
        "https://www.linkedin.com/in/kre%C5%A1imir-kri%C5%A1to-b01355113/",
      imageSrc: {
        default: Kresimir,
      },
    },
    {
      id: 4,
      name: "Nemanja Simikic",
      position: "Lead Technologist",
      linkedinUrl:
        "https://www.linkedin.com/in/nemanja-simiki%C4%87-56b071154/",
      imageSrc: {
        default: Simikic,
      },
    },
    {
      id: 5,
      name: "Zoran Damjanov",
      linkedinUrl: "https://www.linkedin.com/in/zoran-damjanov-11870059/",
      imageSrc: {
        default: Damjanov,
      },
    },
    {
      id: 6,
      name: "Andrija Sagi",
      linkedinUrl: "https://www.linkedin.com/in/andrija-sagi/",
      imageSrc: {
        default: Andrija,
      },
    },
    {
      id: 7,
      name: "Dejan Vukmirovic",
      linkedinUrl: "https://www.linkedin.com/in/dejan-vukmirovic-66554880",
      imageSrc: {
        default: Dejan,
      },
    },
    {
      id: 8,
      name: "Miljko Miljkovic",
      linkedinUrl: "https://www.linkedin.com/in/miljko-miljkovic-a96b44113/",
      imageSrc: {
        default: Miljko,
      },
    },
    {
      id: 9,
      name: "Blagoje Bulajic",
      linkedinUrl: "https://www.linkedin.com/in/blagoje-bulajic-973475b9/",
      imageSrc: {
        default: Blagoje,
      },
    },
    {
      id: 10,
      name: "Viktorija Petrov",
      linkedinUrl: "https://www.linkedin.com/in/viktorija-petrov-56026a4/",
      imageSrc: {
        default: Viktorija,
      },
    },
    {
      id: 11,
      name: "Ana Vukmirovic",
      linkedinUrl: "https://www.linkedin.com/in/ana-vukmirovi%C4%87-7a899a107/",
      imageSrc: {
        default: Ana,
      },
    },
    {
      id: 12,
      name: "Visnja Sretenovic",
      linkedinUrl: "https://www.linkedin.com/in/visnja-sretenovic-051b951b1/",
      imageSrc: {
        default: Visnja,
      },
    },
  ];

  return (
    <div className={classes["team-section-container"]} id="team">
      <div className={classes["team-section-content"]}>
        <h2 className={classes["team-section-text"]}>Team</h2>
        <div className={classes["team-section-members"]}>
          {team.map((member) => {
            return <TeamMember key={member.id} member={member} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Team;
