import React, { useState, useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import SectionTwoProfessor from "./SectionTwoProfessor";
import * as THREE from "three";

let positionOffsetX;
let positionOffsetY;
let positionOffsetZ;
let scale;

const SectionTwoScene = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/section-two-scene.glb");
  const { actions } = useAnimations(animations, group);

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (deviceWidth <= 480) {
    positionOffsetX = 0.2;
    positionOffsetY = 0.7;
    positionOffsetZ = 0;
    scale = 0.4;
  } else if (deviceWidth > 480 && deviceWidth <= 1024) {
    positionOffsetX = 0;
    positionOffsetY = 0;
    positionOffsetZ = 0;
    scale = 0.9;
  } else if (deviceWidth > 1024 && deviceWidth < 1920) {
    positionOffsetX = 0;
    positionOffsetY = 0;
    positionOffsetZ = 0;

    scale = 1;
  } else if (deviceWidth >= 1920) {
    positionOffsetX = 0;
    positionOffsetY = 0;
    positionOffsetZ = 0;

    scale = 1;
  }

  const [isAnimating, setIsAnimating] = useState(true);
  useEffect(() => {
    if (props.inView === false) {
      setIsAnimating(false);
    } else {
      setIsAnimating(true);
    }
  }, [props.inView, isAnimating]);

  useEffect(() => {
    actions.Scene.clampWhenFinished = true;
    actions.Scene.setLoop(THREE.LoopOnce);
    actions.Scene.play();
  }, []);

  useEffect(() => {
    if (isAnimating) {
      actions.Scene.paused = false;
    } else {
      actions.Scene.paused = true;
    }
  }, [isAnimating]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={[positionOffsetX, positionOffsetY, positionOffsetZ]}
      scale={scale}
    >
      <group name="Scene">
        <group
          name="Laptop_screen"
          position={[3.13, 0.006, -2.53]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <group
          name="Empty"
          position={[-1.14, 0.995, -2.606]}
          rotation={[2.793, -0.445, -0.151]}
          scale={0.953}
        >
          <mesh
            name="Logo_Cinteraction_0"
            geometry={nodes.Logo_Cinteraction_0.geometry}
            material={materials["Logo B"]}
            position={[0.003, -0.007, 0.211]}
            rotation={[0, 0, 1.364]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_1"
            geometry={nodes.Logo_Cinteraction_1.geometry}
            material={materials["Logo A"]}
            position={[0.003, -0.007, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_2"
            geometry={nodes.Logo_Cinteraction_2.geometry}
            material={materials["Logo A"]}
            position={[-0.233, -0.004, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_3"
            geometry={nodes.Logo_Cinteraction_3.geometry}
            material={materials["Logo A"]}
            position={[0.218, -0.174, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_4"
            geometry={nodes.Logo_Cinteraction_4.geometry}
            material={materials["Logo A"]}
            position={[0.025, 0.119, 0.251]}
            scale={0.036}
          />
        </group>
        <group
          name="Empty001"
          position={[-2.68, 1.381, -2.194]}
          rotation={[3.068, -0.565, -0.002]}
          scale={1.376}
        >
          <mesh
            name="Logo_Cinteraction_0001"
            geometry={nodes.Logo_Cinteraction_0001.geometry}
            material={materials["Logo B"]}
            position={[0.003, -0.007, 0.211]}
            rotation={[0, 0, 1.364]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_1001"
            geometry={nodes.Logo_Cinteraction_1001.geometry}
            material={materials["Logo A"]}
            position={[0.003, -0.007, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_2001"
            geometry={nodes.Logo_Cinteraction_2001.geometry}
            material={materials["Logo A"]}
            position={[-0.233, -0.004, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_3001"
            geometry={nodes.Logo_Cinteraction_3001.geometry}
            material={materials["Logo A"]}
            position={[0.218, -0.174, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_4001"
            geometry={nodes.Logo_Cinteraction_4001.geometry}
            material={materials["Logo A"]}
            position={[0.025, 0.119, 0.251]}
            scale={0.036}
          />
        </group>
        <group
          name="Empty002"
          position={[3.243, 1.031, -2.762]}
          rotation={[2.833, 0.523, 0.193]}
          scale={1.329}
        >
          <mesh
            name="Logo_Cinteraction_0002"
            geometry={nodes.Logo_Cinteraction_0002.geometry}
            material={materials["Logo B"]}
            position={[0.003, -0.007, 0.211]}
            rotation={[0, 0, 1.364]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_1002"
            geometry={nodes.Logo_Cinteraction_1002.geometry}
            material={materials["Logo A"]}
            position={[0.003, -0.007, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_2002"
            geometry={nodes.Logo_Cinteraction_2002.geometry}
            material={materials["Logo A"]}
            position={[-0.233, -0.004, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_3002"
            geometry={nodes.Logo_Cinteraction_3002.geometry}
            material={materials["Logo A"]}
            position={[0.218, -0.174, 0.251]}
            scale={0.036}
          />
          <mesh
            name="Logo_Cinteraction_4002"
            geometry={nodes.Logo_Cinteraction_4002.geometry}
            material={materials["Logo A"]}
            position={[0.025, 0.119, 0.251]}
            scale={0.036}
          />
        </group>
        <group
          name="Tablet"
          position={[-1.031, 0, -2.349]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          <mesh
            name="Scene005"
            geometry={nodes.Scene005.geometry}
            material={materials.Body}
          />
          <mesh
            name="Scene005_1"
            geometry={nodes.Scene005_1.geometry}
            material={materials.Display}
          />
          <mesh
            name="Scene005_2"
            geometry={nodes.Scene005_2.geometry}
            material={materials["Body_Parts.001"]}
          />
        </group>
        <group
          name="monitor_i_logo"
          position={[-2.663, 0, -2.208]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          <mesh
            name="Scene018"
            geometry={nodes.Scene018.geometry}
            material={materials.Body}
          />
          <mesh
            name="Scene018_1"
            geometry={nodes.Scene018_1.geometry}
            material={materials.Display}
          />
        </group>
        <group
          name="Laptop_screen001"
          position={[3.099, 0.044, -2.528]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          <mesh
            name="Scene019"
            geometry={nodes.Scene019.geometry}
            material={materials.Body}
          />
          <mesh
            name="Scene019_1"
            geometry={nodes.Scene019_1.geometry}
            material={materials.Display}
          />
          <mesh
            name="Scene019_2"
            geometry={nodes.Scene019_2.geometry}
            material={materials.Camera}
          />
        </group>
        <group
          name="Laptop_base"
          position={[2.616, -0.012, -1.721]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          <mesh
            name="Scene020"
            geometry={nodes.Scene020.geometry}
            material={materials.Body}
          />
          <mesh
            name="Scene020_1"
            geometry={nodes.Scene020_1.geometry}
            material={materials.metal_base_down}
          />
          <mesh
            name="Scene020_2"
            geometry={nodes.Scene020_2.geometry}
            material={materials.Body_Parts}
          />
        </group>
        <SectionTwoProfessor inView={props.inView} />
      </group>
    </group>
  );
};

export default SectionTwoScene;

useGLTF.preload("/section-two-scene.glb");
