import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

let positionOffsetX;
let positionOffsetY;
let positionOffsetZ;

let scale;

const HeroStudent = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/hero-student.glb");
  const { actions } = useAnimations(animations, group);
  materials.Student.vertexColors = false;

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (deviceWidth <= 480) {
    positionOffsetX = -0.4;
    positionOffsetY = 0.2;
    positionOffsetZ = 0;

    scale = 0.6;
  } else if (deviceWidth > 480 && deviceWidth <= 1024) {
    positionOffsetX = 0;
    positionOffsetY = 0.45;
    positionOffsetZ = -0.8;
    scale = 0.6;
  } else if (deviceWidth > 1024 && deviceWidth < 1920) {
    positionOffsetX = 1;
    positionOffsetY = 0.2;
    positionOffsetZ = -1;
    scale = 0.9;
  } else if (deviceWidth >= 1920) {
    positionOffsetX = 0.9;
    positionOffsetY = 0.1;
    positionOffsetZ = -1;
    scale = 1;
  }

  const [isAnimating, setIsAnimating] = useState(true);
  useEffect(() => {
    if (props.inView === false) {
      setIsAnimating(false);
    } else {
      setIsAnimating(true);
    }
  }, [props.inView, isAnimating]);

  useEffect(() => {
    actions["Student S0"].clampWhenFinished = true;
    actions["Student S0"].setLoop(THREE.LoopOnce);
    actions["Student S0"].play();
  }, []);

  useEffect(() => {
    if (isAnimating) {
      actions["Student S0"].paused = false;
    } else {
      actions["Student S0"].paused = true;
    }
  }, [isAnimating]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={[positionOffsetX, positionOffsetY, positionOffsetZ]}
      scale={scale}
    >
      <group name="Scene">
        <group name="root" scale={0.01}>
          <primitive object={nodes.rootx} />
          <skinnedMesh
            name="Student"
            geometry={nodes.Student.geometry}
            material={materials.Student}
            skeleton={nodes.Student.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default HeroStudent;

useGLTF.preload("/hero-student.glb");
