import React, { useRef, useState, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const SectionTwoProfessor = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/section-two-professor.glb"
  );
  const { actions } = useAnimations(animations, group);

  nodes.Book.visible = false;
  nodes["Logo_Cinteraction_0"].visible = false;

  const [isAnimating, setIsAnimating] = useState(true);
  useEffect(() => {
    if (props.inView === false) {
      setIsAnimating(false);
    } else {
      setIsAnimating(true);
    }
  }, [props.inView, isAnimating]);

  useEffect(() => {
    actions["Profesor S2"].clampWhenFinished = true;
    actions["Profesor S2"].setLoop(THREE.LoopOnce);
    actions["Profesor S2"].play();
  }, []);

  useEffect(() => {
    if (isAnimating) {
      actions["Profesor S2"].paused = false;
    } else {
      actions["Profesor S2"].paused = true;
    }
  }, [isAnimating]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="root" scale={0.01}>
          <primitive object={nodes.rootx} />
          <skinnedMesh
            name="Telo"
            geometry={nodes.Telo.geometry}
            material={materials.Profesor}
            skeleton={nodes.Telo.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default SectionTwoProfessor;

useGLTF.preload("/section-two-professor.glb");
