import React, { useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import SectionOneStudent from "./SectionOneStudent";

let positionOffsetX;
let positionOffsetY;
let positionOffsetZ;

let scale;

const SectionOnePropsA = (props) => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (deviceWidth <= 480) {
    positionOffsetX = 1.45;
    positionOffsetY = 0;
    positionOffsetZ = 0;

    scale = 0.5;
  } else if (deviceWidth > 480 && deviceWidth <= 1024) {
    positionOffsetX = 0;
    positionOffsetY = 0.35;
    positionOffsetZ = -0.2;

    scale = 0.7;
  } else if (deviceWidth > 1024 && deviceWidth < 1920) {
    positionOffsetX = -0.1;
    positionOffsetY = 0.1;
    positionOffsetZ = 0;

    scale = 0.9;
  } else if (deviceWidth >= 1920) {
    positionOffsetX = -1;
    positionOffsetY = 0;
    positionOffsetZ = 0;
    scale = 1;
  }

  const { nodes, materials } = useGLTF("/section-one-props-a.glb");
  return (
    <group
      {...props}
      dispose={null}
      position={[positionOffsetX, positionOffsetY, positionOffsetZ]}
      scale={scale}
    >
      <SectionOneStudent inView={props.inView} />
      <group
        position={[0, 0.15, 0]}
        rotation={[Math.PI / 2, 0, 0.371]}
        scale={0.01}
      >
        <group
          position={[-167.8, -310.6, 14.8]}
          rotation={[0, 0, -0.172]}
          scale={[2.014, 2.014, 2.25]}
        >
          <mesh geometry={nodes.Cvece.geometry} material={materials.Cvece} />
          <mesh
            geometry={nodes.Saksija_.geometry}
            material={materials.Saksija_}
          />
          <mesh geometry={nodes.Stablo.geometry} material={materials.Stablo} />
        </group>
        <group
          position={[-436.8, -210.3, 14.8]}
          rotation={[0, 0, -0.722]}
          scale={[1.177, 1.177, 1.315]}
        >
          <mesh
            geometry={nodes.Cvece_1.geometry}
            material={materials.Cvece}
            position={[0, 0, -2.7]}
            scale={0.638}
          />
          <mesh
            geometry={nodes.Saksija__1.geometry}
            material={materials.Saksija_}
          />
          <mesh
            geometry={nodes.Stablo_1.geometry}
            material={materials.Stablo}
            position={[0, 0, -2.7]}
            scale={0.638}
          />
        </group>
        <group
          position={[-112, 47, -5.378]}
          rotation={[0, 0, -0.024]}
          scale={2.317}
        >
          <mesh
            geometry={nodes.Knjiga1.geometry}
            material={materials.Knjige_Edited}
            position={[-27.67, 4.13, 1.944]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.885}
            castShadow={true}
          />
          <mesh
            geometry={nodes.Knjiga2.geometry}
            material={materials.Knjige_Edited}
            position={[-27.67, 4.13, 1.944]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={0.885}
            castShadow={true}
          />
        </group>
        <group
          position={[-196.1, -61.8, 9.7]}
          rotation={[0, 0, 0.183]}
          scale={1.248}
        >
          <mesh
            geometry={nodes.Scene002.geometry}
            material={materials.Sofa}
            receiveShadow={true}
          />
          <mesh
            geometry={nodes.Scene002_1.geometry}
            material={materials.Wood}
          />
        </group>
        <mesh
          geometry={nodes.Tepih.geometry}
          material={materials.Tepih_Color}
          position={[-163.702, -48.599, 14.695]}
          rotation={[0, 0, -0.371]}
          scale={[2.403, 2.403, 3.89]}
          receiveShadow={true}
        />
      </group>
    </group>
  );
};

export default SectionOnePropsA;

useGLTF.preload("/section-one-props-a.glb");
