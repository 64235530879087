import React, { useState, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const SectionOneStudent = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/section-one-student.glb");
  const { actions } = useAnimations(animations, group);

  const [isAnimating, setIsAnimating] = useState(false);

  nodes.Tablet.visible = false;

  useEffect(() => {
    if (props.inView === false) {
      setIsAnimating(false);
    } else {
      setIsAnimating(true);
    }
  }, [props.inView, isAnimating]);

  useEffect(() => {
    // actions["Student S1"].clampWhenFinished = true;
    // actions["Student S1"].setLoop(THREE.LoopOnce);
    actions["Student S1"].play();
  }, []);

  useEffect(() => {
    if (isAnimating) {
      actions["Student S1"].paused = false;
    } else {
      actions["Student S1"].paused = true;
    }
  }, [isAnimating]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="root" scale={0.01}>
          <primitive object={nodes.rootx} />
          <skinnedMesh
            name="Student"
            geometry={nodes.Student.geometry}
            material={materials["Karakter.001"]}
            skeleton={nodes.Student.skeleton}
            castShadow={true}
          />
        </group>
      </group>
    </group>
  );
};

export default SectionOneStudent;

useGLTF.preload("/section-one-student.glb");
