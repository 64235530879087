import React, { useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import SectionOneProfessor from "./SectionOneProfessor";

let positionOffsetX;
let positionOffsetY;
let positionOffsetZ;

let scale;

const SectionOnePropsB = (props) => {
  const { nodes, materials } = useGLTF("/section-one-props-b.glb");

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (deviceWidth <= 480) {
    positionOffsetX = 1.25;
    positionOffsetY = 0;
    positionOffsetZ = 0;

    scale = 0.5;
  } else if (deviceWidth > 480 && deviceWidth <= 1024) {
    positionOffsetX = -0.2;
    positionOffsetY = 0.35;
    positionOffsetZ = -0.2;

    scale = 0.7;
  } else if (deviceWidth > 1024 && deviceWidth < 1920) {
    positionOffsetX = -0.1;
    positionOffsetY = 0.1;
    positionOffsetZ = 0;

    scale = 0.9;
  } else if (deviceWidth >= 1920) {
    positionOffsetX = -1;
    positionOffsetY = 0;
    positionOffsetZ = 0;
    scale = 1;
  }

  return (
    <group
      {...props}
      dispose={null}
      position={[positionOffsetX, positionOffsetY, positionOffsetZ]}
      scale={scale}
    >
      <SectionOneProfessor inView={props.inView} />
      <group
        position={[0, 0.15, 0]}
        rotation={[Math.PI / 2, 0, 0.371]}
        scale={0.01}
      >
        <group
          position={[112.25, -90.277, 15]}
          rotation={[0, 0, 0.231]}
          scale={0.88}
        >
          <mesh
            geometry={nodes.Scene004.geometry}
            material={materials.Phone_Body}
          />
          <mesh
            geometry={nodes.Scene004_1.geometry}
            material={materials.Phone_Screen_Edited}
          />
        </group>
      </group>
    </group>
  );
};

export default SectionOnePropsB;

useGLTF.preload("/section-one-props-b.glb");
