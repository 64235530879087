import classes from "./Footer.module.css";
import CinteractionLogo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  let year = new Date().getFullYear();

  return (
    <div className={classes.footer}>
      <div className={`${"container"} ${classes["footer-container"]}`}>
        <div className={classes["footer-content"]}>
          <div className={`${"col"} ${classes["footer-col-left"]}`}>
            <ul>
              <li>
                <a href="#home" rel="noreferrer">
                  Home
                </a>
              </li>
              <li>
                <a href="#solution" rel="noreferrer">
                  Our Solution
                </a>
              </li>
              <li>
                <a href="#tehnology" rel="noreferrer">
                  Tehnology
                </a>
              </li>
              <li>
                <a href="#support" rel="noreferrer">
                  Support
                </a>
              </li>
              <li>
                <a href="#team" rel="noreferrer">
                  Team
                </a>
              </li>
              <li>
                <a
                  href="https://cinteraction.com/app"
                  target="_blank"
                  rel="noreferrer"
                >
                  VC Demo
                </a>
              </li>
              <li>
                <NavLink to="/privacy" target="_blank">Privacy</NavLink>
              </li>
              {/*<li>*/}
              {/*  <NavLink to="/terms">Terms</NavLink>*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="spacer"></div>
          <div className={`${"col"} ${classes["footer-col-right"]}`}>
            <img src={CinteractionLogo} alt="Cinteraction"/>
          </div>
        </div>
        <div className={classes["footer-underline"]}>
          <p>All Rights Reserved. &copy; {year} Cinteraction</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
