import classes from "./TeamMember.module.css";

const TeamMember = (props) => {
  const { name, position, imageSrc, linkedinUrl } = props.member;

  return (
    <a
      className={classes["team-member"]}
      href={linkedinUrl}
      target="_blank"
      rel="noreferrer"
    >
      <div className={classes["image-holder"]}>
        <img
          src={imageSrc.default}
          className={classes["default-img"]}
          alt="Team Member"
        />
      </div>
      <div className={classes["info"]}>
        <p>{name}</p>
        <small>{position}</small>
      </div>
    </a>
  );
};

export default TeamMember;
