import classes from "./SideDrawer.module.css";
import NavbarContext from "../../store/navbar-context";
import CloseIcon from "../CloseIcon";
import Logo from "../../../assets/logo.png";
import Chevron from "../../../assets/chevron.png";
import React, { useContext } from "react";

const SideDrawer = () => {
  const navbarCtx = useContext(NavbarContext);

  return (
    <div
      className={
        navbarCtx.sideDrawerOpen
          ? ` ${classes["side-drawer"]} ${classes.open}`
          : classes["side-drawer"]
      }
    >
      <div className={classes["drawer-content"]}>
        <div className={classes["drawer-header"]}>
          <img
            className={classes["drawer-logo"]}
            src={Logo}
            alt="Cinteraction"
          />
          <CloseIcon />
        </div>
        <div className={classes["drawer-links"]}>
          <ul>
            <li className={classes["drawer-link"]}>
              <a href="#home" rel="noreferrer" onClick={navbarCtx.hideNavbar}>
                <span className={classes.span}> Home</span>
                <span className={classes.chevron} />
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="#solution"
                rel="noreferrer"
                onClick={navbarCtx.hideNavbar}
              >
                <span className={classes.span}> Our Solution</span>
                <span className={classes.chevron} />
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="#tehnology"
                rel="noreferrer"
                onClick={navbarCtx.hideNavbar}
              >
                <span className={classes.span}> Tehnology</span>
                <span className={classes.chevron} />
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="#support"
                rel="noreferrer"
                onClick={navbarCtx.hideNavbar}
              >
                <span className={classes.span}> Support</span>
                <span className={classes.chevron} />
              </a>
            </li>

            <li className={classes["drawer-link"]}>
              <a href="#team" rel="noreferrer" onClick={navbarCtx.hideNavbar}>
                <span className={classes.span}> Team</span>
                <span className={classes.chevron} />
              </a>
            </li>
            <li className={classes["drawer-link"]}>
              <a
                href="https://cinteraction.com/app"
                target="_blank"
                rel="noreferrer"
                onClick={navbarCtx.hideNavbar}
              >
                <span className={classes.span}> VC Demo</span>
                <span className={classes.chevron} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
