import classes from "./Advisers.module.css";
import AdviserMember from "./AdviserMember";
import React from "react";
import Niculae from "../../assets/team/NicuSebe.png";
import Borko from "../../assets/team/Borko Furht.png";
import Daniel from "../../assets/team/Daniel.png";

const Advisers = () => {
  const advisers = [
    {
      id: 1,
      name: "Prof. Niculae Sebe",
      position: "Advisor",
      linkedinUrl: "https://www.linkedin.com/in/nicu-sebe-9045ba13/",
      imageSrc: {
        default: Niculae,
      },
    },
    {
      id: 2,
      name: "Prof. Borko Furht",
      position: "Advisor",
      linkedinUrl: "https://www.linkedin.com/in/borko-furht-5913b32/",
      imageSrc: {
        default: Borko,
      },
    },
    {
      id: 3,
      name: "Dr Daniel Socek",
      position: "Advisor",
      linkedinUrl: "https://www.linkedin.com/in/daniel-socek-59a41b2b/",
      imageSrc: {
        default: Daniel,
      },
    },
  ];

  return (
    <div className={classes["advisers-section-container"]}>
      <div className={classes["advisers-section-content"]}>
        <h2 className={classes["advisers-section-text"]}>Advisers</h2>
        <div className={classes["advisers-section-members"]}>
          {advisers.map((member) => {
            return <AdviserMember key={member.id} member={member} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Advisers;
