import ReusableCarouselItem from "./ReusableCarouselItem";
import classes from "./ReusableCarousel.module.css";

const ReusableCarousel = (props) => {
  return (
    <div className={`${classes["carousel-viewport"]} ${props.className}`}>
      <div className={classes.carousel}>
        <div className={classes["carousel-container"]}>
          <div className={classes["carousel-track"]}>
            {Object.keys(props.cardDetails).map((detailKey) => {
              return (
                <ReusableCarouselItem
                  key={detailKey}
                  link={props.cardDetails[detailKey].link}
                  imgUrl={props.cardDetails[detailKey].imgUrl}
                  imgTitle={props.cardDetails[detailKey].title}
                ></ReusableCarouselItem>
              );
            })}
          </div>
          <div className={classes["carousel-track-two"]}>
            {Object.keys(props.cardDetails).map((detailKey) => {
              return (
                <ReusableCarouselItem
                  key={detailKey}
                  link={props.cardDetails[detailKey].link}
                  imgUrl={props.cardDetails[detailKey].imgUrl}
                  imgTitle={props.cardDetails[detailKey].title}
                ></ReusableCarouselItem>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableCarousel;
