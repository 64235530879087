import React, { useRef } from "react";
import { useGLTF, PerspectiveCamera } from "@react-three/drei";

const SectionTwoCamera = (props) => {
  const { nodes, materials } = useGLTF("/section-two-camera.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[0.21, 1.179, 12.018]} rotation={[-3.058, 0, 3.142]}>
        <PerspectiveCamera
          makeDefault={true}
          far={50}
          near={0.01}
          fov={25}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
    </group>
  );
};

export default SectionTwoCamera;

useGLTF.preload("/section-two-camera.glb");
