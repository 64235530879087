import React, { useContext } from "react";
import classes from "./BurgerMenu.module.css";
import NavbarContext from "../store/navbar-context";
import Burger from "../../assets/burger.png";

const BurgerMenu = () => {
  const navbarCtx = useContext(NavbarContext);

  return (
    <div className={classes["burger-menu"]} onClick={navbarCtx.showNavbar}>
      <img src={Burger} />
    </div>
  );
};

export default BurgerMenu;
