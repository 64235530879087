import classes from "./index.module.css";
import Header from "../../components/Header/Header";

function TermsOfUse() {
  return (
    <div>
      <Header />
      <h1>This is Terms Of Use</h1>
    </div>
  )
}

export default TermsOfUse;
