import React, { useContext } from "react";
import classes from "./Hero.module.css";
import Header from "../Header/Header";
import HeroCarousel from "../Carousel/HeroCarousel";
import NavbarContext from "../store/navbar-context";
import SideDrawer from "../Header/SideDrawer/SideDrawer";
import Stage from "../3dModels/Stage";
import { Canvas, useFrame } from "@react-three/fiber";
import { useInView } from "react-intersection-observer";
import HeroStudent from "./HeroStudent";
import HeroProfessor from "./HeroProfessor";
import HeroCamera from "./HeroCamera";
const Hero = () => {
  const DisableRender = () => useFrame(() => null, 1000);
  const { ref, inView } = useInView();

  const navbarCtx = useContext(NavbarContext);

  return (
    <React.Fragment>
      <div className={classes.hero} ref={ref} id="home">
        <Canvas
          style={{
            position: "absolute",
          }}
          resize={{ scroll: false }}
        >
          {!inView && <DisableRender />}
          <Stage />
          <HeroCamera />
          <HeroStudent inView={inView} />
          <HeroProfessor inView={inView} />
        </Canvas>
        <Header />
        <SideDrawer />
        <div className={classes["hero-container"]}>
          <div className={classes["hero-content"]}>
            <div className={classes["hero-col-left"]}>
              <h1>Welcome to Cinteraction</h1>
              <p>
                We are Virtual Conferencing of the Future (VCF), an intelligent
                AI-enabled video-conferencing solution
              </p>
            </div>
            <div className={classes["hero-col-right"]}></div>
          </div>
        </div>
        <HeroCarousel />
      </div>
    </React.Fragment>
  );
};

export default Hero;
