import React, { useState, useContext } from "react";
import HeaderLogo from "../../assets/logo.png";
import classes from "./Header.module.css";
import NavbarContext from "../store/navbar-context";
import SideDrawer from "./SideDrawer/SideDrawer";
import BurgerMenu from "./BurgerMenu";
import { useLocation } from "react-router-dom";

const Header = () => {
  const navbarCtx = useContext(NavbarContext);
  const { pathname } = useLocation();

  return (
    <div className={classes.header}>
      <div className="container">
        <div className={`${"content"} ${classes["header-content"]}`}>
          <img
            className={classes["header-logo"]}
            src={HeaderLogo}
            alt="Header Logo"
          />
          { pathname === '/' ? (
            <>
              <div className={classes["header-navigation"]}>
                <ul>
                  <li>
                    <a href="#home" rel="noreferrer">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#solution" rel="noreferrer">
                      Our Solution
                    </a>
                  </li>
                  <li>
                    <a href="#tehnology" rel="noreferrer">
                      Tehnology
                    </a>
                  </li>
                  <li>
                    <a href="#support" rel="noreferrer">
                      Support
                    </a>
                  </li>
                  <li>
                    <a href="#team" rel="noreferrer">
                      Team
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://cinteraction.com/app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      VC Demo
                    </a>
                  </li>
                </ul>
              </div>
              {!navbarCtx.SideDrawerOpen && <BurgerMenu />}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
