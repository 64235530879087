import Hero from "../../components/Hero/Hero";
import SectionOne from "../../components/SectionOne/SectionOne";
import SectionTwo from "../../components/SectionTwo/SectionTwo";
import SectionThree from "../../components/SectionThree/SectionThree";
import SectionFour from "../../components/SectionFour/SectionFour";
import Footer from "../../components/Footer/Footer";
import Team from "../../components/Team/Team";
import NavbarProvider from "../../components/store/NavbarProvider";
import Advisers from "../../components/Team/Advisers";
import SupportedBySection from "../../components/SupportedBy/SupportedBySection";

function Home() {
  return (
    <NavbarProvider>
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Team />
      <Advisers />
      <SupportedBySection />
      <Footer />
    </NavbarProvider>
  );
}

export default Home;
