import React, { useRef } from "react";
import { useGLTF, PerspectiveCamera } from "@react-three/drei";

const HeroCamera = (props) => {
  const { nodes, materials } = useGLTF("/hero-camera.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-0.53, 1.656, 6.556]} rotation={[3.027, -0.057, 3.135]}>
        <PerspectiveCamera
          makeDefault={true}
          far={50}
          near={0.01}
          fov={30}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
    </group>
  );
};

export default HeroCamera;

useGLTF.preload("/hero-camera.glb");
