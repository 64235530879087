import classes from "./SupportedBySection.module.css";
import SupportedLogoPartOne from "../../assets/supportedby/supported-logo-one.png";
import SupportedLogoPartTwo from "../../assets/supportedby/supported-logo-two.png";

const SupportedBySection = () => {
  return (
    <div className={classes["supported-by-container"]}>
      <h2 className={classes["supported-title"]}>Supported by</h2>
      <a
        href="https://www.eitdigital.eu/"
        target="_blank"
        rel="noreferrer"
        className={classes["supported-by-content"]}
      >
        <div className={classes["support-img-container-left"]}>
          <img src={SupportedLogoPartOne} alt="Supported by" />
        </div>
        <div className={classes["support-img-container-right"]}>
          <img src={SupportedLogoPartTwo} alt="Supported by" />
        </div>
      </a>
    </div>
  );
};

export default SupportedBySection;
