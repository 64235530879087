import classes from "./SectionOne.module.css";
import { Canvas, useFrame } from "@react-three/fiber";
import WhiteStage from "../3dModels/WhiteStage";
import { useInView } from "react-intersection-observer";
import SectionOnePropsA from "./SectionOnePropsA";
import SectionOnePropsB from "./SectionOnePropsB";
import SectionOneCamera from "./SectionOneCamera";
import Fade from "react-reveal/Fade";

const SectionOne = () => {
  const DisableRender = () => useFrame(() => null, 1000);
  const { ref, inView } = useInView();

  return (
    <div className={classes["s-one"]} ref={ref} id="solution">
      <Canvas style={{ position: "absolute" }} shadows={"basic"}>
        {!inView && <DisableRender />}
        <WhiteStage />
        <SectionOneCamera />
        <SectionOnePropsA inView={inView} />
        <SectionOnePropsB inView={inView} />
      </Canvas>
      <Fade right>
        <div className={classes["s-one-container"]}>
          <div className={classes["s-one-col-left"]}></div>

          <div className={classes["s-one-col-right"]}>
            <h2>Top-of-the-line engagement monitoring</h2>
            <p>
              VCF provides real-time data on all users’ behavior during video
              conferencing. Our intelligent AI analyzes complex human
              interactions, improving the effectiveness of online meetings.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default SectionOne;
