import ReusableCarousel from "./ReusableCarousel";

// import PartnerOne from "../../assets/carousel/eitDigital.png";
import PartnerTwo from "../../assets/carousel/InovationFond.png";
import PartnerThree from "../../assets/carousel/NSWD.png";
import PartnerFour from "../../assets/carousel/MCS.png";
import PartnerFive from "../../assets/carousel/Infora.png";
import PartnerSix from "../../assets/carousel/ivi.png";
import PartnerSeven from "../../assets/carousel/MALTA.png";
import PartnerEight from "../../assets/carousel/Ethernal.png";

const HeroCarousel = () => {
  const cardDetails = {
    // 0: {
    //   imgUrl: PartnerOne,
    //   title: "Partner 1",
    //   link: "https://www.eitdigital.eu/",
    // },

    0: {
      imgUrl: PartnerTwo,
      title: "Partner 1",
      link: "http://www.inovacionifond.rs/cir/",
    },

    1: {
      imgUrl: PartnerThree,
      title: "Partner 3",
      link: "https://nswebdevelopment.com/",
    },

    2: {
      imgUrl: PartnerFour,
      title: "Partner 4",
      link: "https://www.machinecansee.com/",
    },

    3: {
      imgUrl: PartnerFive,
      title: "Partner 5",
      link: "https://infora.rs/#/		",
    },

    4: {
      imgUrl: PartnerSix,
      title: "Partner 6",
      link: "https://www.ivi.ac.rs/",
    },
    5: {
      imgUrl: PartnerSeven,
      title: "Partner 7",
      link: "https://www.maltaccelerate.com/",
    },
    6: {
      imgUrl: PartnerEight,
      title: "Partner 8",
      link: "https://ethernal.tech/",
    },
  };
  return <ReusableCarousel cardDetails={cardDetails} />;
};

export default HeroCarousel;
