import React, { useRef } from "react";
import { PerspectiveCamera, useGLTF } from "@react-three/drei";
import * as THREE from "three";

const WhiteStage = (props) => {
  let hemisphereLightColor = new THREE.Color("rgb(176,230,255)");
  let groundColor = new THREE.Color("rgb(255,170,0)");
  let ambientLightColor = new THREE.Color("rgb(34,34,34)");
  const { nodes, materials } = useGLTF("/stage.glb");

  return (
    <group {...props} dispose={null}>
      {/* <PerspectiveCamera
        makeDefault={true}
        far={50}
        near={0.01}
        fov={30}
        position={[-3.5, 1.234, 6]}
        rotation={[-0.003, -0.4, 0]}
      /> */}
      <spotLight
        intensity={50}
        angle={Math.PI / 2}
        decay={2}
        distance={100}
        position={[2.903, 4.273, 6.097]}
        rotation={[2.405, 0.707, -2.32]}
        castShadow={true}
      />
      <pointLight
        intensity={35.48}
        decay={2}
        distance={200}
        position={[0, 6.93, 0]}
        castShadow={true}
      />
      <ambientLight
        position={[0, 0, 0]}
        color={ambientLightColor}
        intensity={50}
        visible={true}
        frustumCulled={true}
        renderOrder={0}
      />
      <hemisphereLight
        position={[0, 0.82, 0]}
        color={hemisphereLightColor}
        groundColor={groundColor}
        intensity={1}
        visible={true}
        frustumCulled={true}
        renderOrder={0}
      />
    </group>
  );
};

export default WhiteStage;
useGLTF.preload("/stage.glb");
