import classes from "./SectionFour.module.css";
import { Canvas, useFrame } from "@react-three/fiber";
import { useInView } from "react-intersection-observer";
import SectionFourImg from "../../assets/section-fourr.png";
import Typewriter from "typewriter-effect";
import Fade from "react-reveal/Fade";

const SectionFour = () => {
  const DisableRender = () => useFrame(() => null, 1000);
  const { ref, inView } = useInView();
  return (
    <div className={classes["s-four"]} ref={ref}>
      {/* <Canvas
       
        shadows="basic"
        style={{ position: "absolute", height: 700 }}
       
      >
        {!inView && <DisableRender />}
        <Stage />
      </Canvas> */}
      <div className={classes["s-four-container"]}>
        <div className={classes["s-four-col-left"]}>
          <div className={classes.typewriter}>
            {inView && (
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Welcome")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("to")
                    .pauseFor(200)
                    .deleteAll()
                    .typeString("the")
                    .pauseFor(200)
                    .deleteAll()
                    .typeString("Future")
                    .pauseFor(2000)
                    .deleteAll()
                    .pauseFor(2000)
                    .start();
                }}
              />
            )}
          </div>
        </div>
        <Fade right>
          <div className={classes["s-four-col-right"]}>
            <h2>
              Automatic <br />
              transcript generation
            </h2>
            <p>
              With integrated advanced speech-to-text technology, VCF enables
              the automatic generation of transcripts.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default SectionFour;
