import classes from "./SectionThree.module.css";
import { Canvas, useFrame } from "@react-three/fiber";
import WhiteStage from "../3dModels/WhiteStage";
import { useInView } from "react-intersection-observer";
import SectionThreeImg from "../../assets/section-three.png";
import Fade from "react-reveal/Fade";

const SectionThree = () => {
  const DisableRender = () => useFrame(() => null, 1000);
  const { ref, inView } = useInView();
  return (
    <div className={classes["s-three"]} ref={ref} id="support">
      {/* <Canvas shadows="basic" style={{ position: "absolute", height: 700 }}>
        {!inView && <DisableRender />}
        <WhiteStage />
      </Canvas> */}

      <div className={classes["s-three-container"]}>
        <Fade left>
          <div className={classes["s-three-col-left"]}>
            <h2>
              Support for <br /> hundreds of participants
            </h2>
            <p>
              By using servers instead of a P2P solution, VCF supports hundreds
              of participants while providing a stable environment for
              communication.
            </p>
          </div>
        </Fade>

        <div className={classes["s-three-col-right"]}>
          <img className={classes["s-three-img"]} src={SectionThreeImg} />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
