import React, { useRef } from "react";
import { useGLTF, PerspectiveCamera } from "@react-three/drei";

const SectionOneCamera = (props) => {
  const { nodes, materials } = useGLTF("/section-one-camera.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-0.03, 1.41, 6.06]} rotation={[3.117, 0, 3.142]}>
        <PerspectiveCamera
          makeDefault={true}
          far={50}
          near={0.01}
          fov={35}
          rotation={[-Math.PI, 0.2, -Math.PI]}
        />
      </group>
    </group>
  );
};

export default SectionOneCamera;

useGLTF.preload("/section-one-camera.glb");
