import classes from "./SectionTwo.module.css";
import Stage from "../3dModels/Stage";
import { Canvas, useFrame } from "@react-three/fiber";
import { useInView } from "react-intersection-observer";
import SectionTwoScene from "./SectionTwoScene";
import SectionTwoCamera from "./SectionTwoCamera";
import Fade from "react-reveal/Fade";

const SectionTwo = () => {
  const DisableRender = () => useFrame(() => null, 1000);
  const { ref, inView } = useInView();
  return (
    <div className={classes["s-two"]} ref={ref} id="tehnology">
      <Canvas shadows="basic" style={{ position: "absolute", height: 700 }}>
        {!inView && <DisableRender />}
        <Stage fov={25} />
        <SectionTwoCamera />
        <SectionTwoScene inView={inView} />
      </Canvas>
      <div className={classes["s-two-container"]}>
        <Fade top>
          <div className={classes["s-two-col-left"]}>
            <h2>
              A unique patent-pending video analysis AI module with API access
            </h2>
            <p>
              VCF allows for easy integration with other platforms. The
              possibilities are almost limitless.
            </p>
          </div>
          <div className={classes["s-two-col-right"]}></div>
        </Fade>
      </div>
    </div>
  );
};

export default SectionTwo;
