import React, { useState, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const SectionOneProfessor = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/section-one-professor.glb"
  );
  const { actions } = useAnimations(animations, group);

  nodes["Logo_Cinteraction_0"].visible = false;
  nodes["Remont_control"].visible = false;

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (props.inView === false) {
      setIsAnimating(false);
    } else {
      setIsAnimating(true);
    }
  }, [props.inView, isAnimating]);

  useEffect(() => {
    // actions["Profesor S1"].clampWhenFinished = true;
    // actions["Profesor S1"].setLoop(THREE.LoopOnce);
    actions["Profesor S1"].play();
  }, []);

  useEffect(() => {
    if (isAnimating) {
      actions["Profesor S1"].paused = false;
    } else {
      actions["Profesor S1"].paused = true;
    }
  }, [isAnimating]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="root" scale={0.01}>
          <primitive object={nodes.rootx} />
          <skinnedMesh
            name="Telo"
            geometry={nodes.Telo.geometry}
            material={materials.Profesor}
            skeleton={nodes.Telo.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default SectionOneProfessor;

useGLTF.preload("/section-one-professor.glb");

// import React, { useRef, useEffect, useState } from "react";
// import { useGLTF, useAnimations } from "@react-three/drei";
// import { useThree, useFrame } from "@react-three/fiber";

// const SectionOneProfessor = (props) => {
//   const group = useRef();
//   const { nodes, materials, animations } = useGLTF(
//     "/section-one-professor.glb"
//   );

//   const { size } = useThree();
//   const { actions } = useAnimations(animations, group);

//   const [isAnimating, setIsAnimating] = useState(false);

//   useEffect(() => {
//     if (props.inView === false) {
//       setIsAnimating(false);
//     } else {
//       setIsAnimating(true);
//     }
//   }, [props.inView, isAnimating]);

//   useEffect(() => {
//     actions["root|Drazo drzi vakelu|Base Layer"].timeScale = 0.5;
//     actions["BookAction"].timeSlace = 0.5;
//     actions["root|Drazo drzi vakelu|Base Layer"].play();
//     actions["BookAction"].play();
//   }, []);

//   useEffect(() => {
//     if (isAnimating) {
//       actions["root|Drazo drzi vakelu|Base Layer"].paused = false;
//       actions["BookAction"].paused = false;
//     } else {
//       actions["root|Drazo drzi vakelu|Base Layer"].paused = true;
//       actions["BookAction"].paused = true;
//     }
//   }, [isAnimating]);

//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group name="Scene" position={[1.9, 0, -0.869]} rotation={[0, -0.692, 0]}>
//         <group name="root" scale={0.01}>
//           <primitive object={nodes.rootx} />

//           <skinnedMesh
//             name="Brada"
//             geometry={nodes.Brada.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Brada.skeleton}
//           />
//           <skinnedMesh
//             name="Brkovi"
//             geometry={nodes.Brkovi.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Brkovi.skeleton}
//           />
//           <skinnedMesh
//             name="Naocare"
//             geometry={nodes.Naocare.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Naocare.skeleton}
//           />
//           <skinnedMesh
//             name="Obrva1"
//             geometry={nodes.Obrva1.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Obrva1.skeleton}
//           />
//           <skinnedMesh
//             name="Obrva2"
//             geometry={nodes.Obrva2.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Obrva2.skeleton}
//           />
//           <skinnedMesh
//             name="Telo"
//             geometry={nodes.Telo.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Telo.skeleton}
//           />
//           <skinnedMesh
//             name="Telo001"
//             geometry={nodes.Telo001.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Telo001.skeleton}
//           />
//           <skinnedMesh
//             name="Usta"
//             geometry={nodes.Usta.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Usta.skeleton}
//           />
//           <skinnedMesh
//             name="Oko1"
//             geometry={nodes.Oko1.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Oko1.skeleton}
//             morphTargetDictionary={nodes.Oko1.morphTargetDictionary}
//             morphTargetInfluences={nodes.Oko1.morphTargetInfluences}
//           />
//           <skinnedMesh
//             name="Oko2"
//             geometry={nodes.Oko2.geometry}
//             material={materials.Karakter2}
//             skeleton={nodes.Oko2.skeleton}
//             morphTargetDictionary={nodes.Oko2.morphTargetDictionary}
//             morphTargetInfluences={nodes.Oko2.morphTargetInfluences}
//           />
//         </group>
//       </group>
//     </group>
//   );
// };

// export default SectionOneProfessor;
// useGLTF.preload("/section-one-professor.glb");
