import classes from "./index.module.css";
import Header from "../../components/Header/Header";

function Privacy() {
  return (
    <div>
      <Header />
      <div className={classes.wrapper}>
        <h1 className="py-5">Rulebook on personal data protection</h1>
        <p className="font-medium">
          Pursuant to Article 41, paragraph 3 of the Law on the Protection of Personal Data ("Official
          Gazette of Republic of Serbia", No. 87/2018, hereinafter: the Law), CINTERACTION DOO
          NOVI SAD, st. Nikolajevska no. 2, registration number: 21594776, tax identification number:
          112038028 (hereinafter: the Controller), Novi Sad, on the 18th of July, puts into effect the
          following
        </p>
        <h2 className="py-5">Rulebook on personal data protection</h2>
        <p className="font-medium">Subject matter and goal</p>
        <p className="font-medium">Article 1</p>
        <p className="py-5 font-medium">
          This Rulebook is the basic internal general act that governs the protection of personal data of
          employees and employed persons, as well as their family members, external consultants and
          other persons who enter into contractual and other legal relations with the Controller, as well as
          other persons whose data the Controller processes (eg. consumers, service users/clients,
          business partners, etc.), in accordance with the Law and other regulations in the field of
          personal data protection.
          The aim of this Rulebook is to ensure legal certainty and transparency in the processing of
          personal data of the data subject, as well as to determine the legal basis, purpose of
          processing, types of data that are processed, the rights of the data subject with regard to the
          processing of personal data, data protection measures and other issues of importance.
          This Rulebook also establishes the obligations of employees of the Controller regarding the
          protection of personal data.
        </p>
        <p className="font-medium">Definitions</p>
        <p className="font-medium">Article 2</p>
        <p className="py-5 font-medium leading-7">
          Certain terms used in this regulation have the following meanings:
          <span className="pl-8 block">
            <span className="block">
              1. Labor Law of the Republic of Serbia ("Official Gazette of Republic of Serbia", nr. 24/2005,
              61/2005, 54/2009, 32/2013, 5/2014, 13/2017 - decision of the Constitutional Court and
              113/2017) (hereinafter: " Labour Law");
            </span>
            <span className="block">
              2. Commissioner for Information of Public Importance and Personal Data Protection of the
              Republic of Serbia (hereinafter: "the Commissioner");
            </span>
            <span className="block">
              3. Personal data is any data relating to a natural person (data subject) whose identity is
              identified or identifiable, directly or indirectly, especially on the basis of an identity
              marker, such as name and identification number, location data, identifiers in electronic
              communication networks or one or more features of their physical, physiological,
              genetic, mental, economic, cultural and social identity;
            </span>
            <span className="block">
              4. Special categories of personal data are the data that reveal racial or ethnic origin, political
              opinion, religious or philosophical belief or trade union membership, genetic data,
              biometric data, data on the state of health, sex life, or sexual orientation of a natural
              person;
            </span>
            <span className="block">
              5. The processing of personal data is any action or set of actions performed automatically or
              non-automated with personal data or their sets, such as collection, recording,
              sorting, grouping, i.e. structuring, storing, adapting or changing, revealing, viewing,
              using, disclosure by transmission, i.e. delivery, duplication, dissemination or
              otherwise making available, comparison, restriction, deletion or destruction (hereinafter:
              processing);
            </span>
            <span className="block">
              6. The term "employee" includes, besides the definition of an employee set out in the Labor
              Law, persons providing services to the Controller on the basis of a service contract,
              copyright contracts, contracts on the provision of consulting services, and other contracts
              that contain a provision obliging the person engaged by the Company to comply with the
              provisions of this Rulebook;
            </span>
            <span className="block">
              7. The term "Client" includes a legal entity or a natural person who is a business entity (e.g.
              an entrepreneur) as well as any organization/institution/establishment with which the
              Company has a specific business relationship.
            </span>
            <span className="block">
              8. "End user" means a natural person to whom the personal data refers to, who is
              authorized by the Client to use the services and products of the Controller, for which use
              the Client pays a fee;
            </span>
            <span className="block">
              9. The Controller is the Company as a legal entity that determines the purpose and method
              of personal data processing in the sense of the Law.
            </span>
            <span className="block">
              10. Joint controllers are all persons who jointly determine the purpose and method of
              personal data processing with the Company, as a controller.
            </span>
            <span className="block">
              11. A Processor is a natural or legal entity that processes personal data on behalf of the
              Controller.
            </span>
            12. The National Employment Service is a public service that provides services to
            unemployed persons and employers in Serbia, which consists of the Directorate, two
            Provincial Services, 34 branches, 21 services, and more than 120 branches in all
            districts of the Republic of Serbia (hereinafter: NES).
          </span>
        </p>
        <p className="font-medium">Personal data processed by the Controller</p>
        <p className="font-medium">Article 3</p>
        <p className="py-5 font-medium leading-7">
          <span className="pl-8 block">
           <span className="block">
              1. The Controller may process the following personal data of the persons with whom the
            Controller cooperates (users of services/clients, business partners, etc.):
           </span>
            <span className="pl-8 block">
              a. name and surname, name of employer/organization/organization that the person
              represents, contact e-mail address.
            </span>
            2. The Controller may process the following personal data of the End User:
            <span className="pl-8 block">
              a. Name and surname, contact e-mail address, photo, audio recording, IP address.
            </span>
            <span className="pl-8 block">
              b. The Controller may process the specified data of the End Users even if they are
              minors, in accordance with the Law.
            </span>
            3. The Controller can process the following personal data of persons that fall under the
            definition of employees and employed persons under this Rulebook:
            <span className="pl-8 block">
              a. name and surname, address, date and place of birth, gender, marital status, social
              security number, ID number, citizenship, health insurance number (LBO);
            </span>
            <span className="pl-8 block">
              b. academic and professional/professional qualifications: degree of education, titles,
              information on skills, knowledge of foreign languages, training, employment
              history, biography; financial data: bank account number, data on earnings and
              additional benefits; data on the performance of work duties;
            </span>
            <span className="pl-8 block">
              c. workplace - position, assessment of superior (supervisor), business e-mail
              address, IP address, access code;
            </span>
            <span className="pl-8 block">
              d. communication data: e-mail address, telephone number, emergency contact of
              relatives;
            </span>
            <span className="pl-8 block">
              e. other data necessary for the execution of the employer's obligations prescribed by
              law and the execution of the employment contract, i.e. the contract regulating
              work outside the employment relationship between the employee, i.e. the person
              employed and the Manager.
            </span>
            <span className="pl-8 block">
              f. The Controller may also process certain categories of special types of personal
              data, in accordance with Article 17 of the Law (for example, processing of special
              types of personal data of employees and their family members for the purpose of
              fulfilling obligations or applying legally prescribed powers in the field of labor
              relations, social insurance and social protection).
            </span>
            4. The controller may process the following personal data of the job candidate:
            <span className="pl-8 block">
              a. name and surname, date and place of birth; academic and professional/professional
              qualifications contained in the work biography and motivation letter: degree of
              education, titles, data on skills, knowledge of foreign languages, trainings, list of
              previous employers; communication data: e-mail address, telephone number.
            </span>
            <span className="pl-8 block">
              b. When announcing a job vacancy, the Controller does not determine the form of
              the work resume, but the job candidate determines it himself, as a result of which
              the Controller can come into possession of a larger volume of data than that
              contained in paragraph 5 of this article, at the discretion of the job candidate. The
              data collected in this way is stored until the end of the selection process, and
              upon the consent of the candidate, it is stored for a period of 1 year for the
              purpose of subsequent assessment of the need to hire the candidate.
            </span>
            <span className="pl-8 block">
              c. The Controller, in accordance with the principle of data minimization, does not
              process a larger quantity or non-specified types of personal data than is
              necessary to fulfill the stated purposes. If the processing of special types of data
              is carried out on the basis of the person's consent (for example, in order to adapt
              the conditions of training or work to the health condition of the participant), that
              consent must be given in writing, which includes detailed information about the
              type of data being processed, the purpose of the processing and way of using
              data.
            </span>
          </span>
        </p>
        <p className="font-medium">Purpose of data processing</p>
        <p className="font-medium">Article 4</p>
        <p className="py-5 font-medium leading-7">
          The Controller processes personal data for the purposes set out in this article, with the provison
          that it does not process more data, i.e. a wider range of data than is necessary to achieve these
          purposes.
          <span className="block">
            The purposes for which personal data are processed are:
          </span>
          <span className="pl-8 block">
            <span className="block">
              1. performance of activities and performance of business activities - the Controller
              processes personal data for the purposes of providing services, selling services and
              products, business development, business cooperation, project management, work
              organization, office operations and other types of performance of activities and
              conducting business activities, including reporting to clients about conducted business
              activities;
            </span>
            <span className="block">
              2. employment and human resources management - The Controller processes personal
              data for the purposes of establishing and implementing employment relationships and
              other forms of work engagement (for example, processing personal data for the
              purposes of: determining the knowledge and skills of candidates for certain positions,
              managing working hours and absences with of work, calculation of wages, travel
              expenses and daily allowances, determination of benefits based on sick leave and other
              forms of absence from the workplace, assessment of employee progress, provision of
              additional training and education and disciplinary procedures);
            </span>
            <span className="block">
              3. communication, information technology and information security - the Controller
              processes personal data for the purpose of managing and maintaining the functioning of
              the communication and information network, maintaining information security and
              preventing the threats of information risks;
            </span>
            <span className="block">
              4. harmonization of business operations with laws and other regulations - the Controller
              processes personal data in order to fulfill prescribed obligations and to harmonize
              operations with laws and other regulations (commercial, labor and tax legislation, etc.).
            </span>
          </span>
        </p>
        <p className="font-medium">Method of collecting personal data</p>
        <p className="font-medium">Article 5</p>
        <p className="py-5 font-medium leading-7">
          The Controller collects personal data directly from the data subject, in electronic form, as well
          as from other sources. All excess data is permanently deleted.
        </p>
        <p className="font-medium">Transfer of data and transfer of data from the Republic of Serbia</p>
        <p className="font-medium">Article 6</p>
        <p className="py-5 font-medium leading-7">
          The Controller may transfer personal data to third parties under the conditions set forth in this
          article, whereby the Controller is obliged to take all necessary measures to ensure that personal
          data is processed and secured in accordance with the Law and other regulations. The Controller
          may employ a third party, in the capacity of a service provider and personal data processor, to
          perform certain actions of personal data processing on behalf of the Controller. In these cases,
          only those data that are necessary to achieve the purpose of the contracted processing may be
          transferred, and the Processors may not use them for other purposes. The terms of data
          processing and the responsibility for data protection are regulated in more detail by the service
          contract between the Controller and the Processor.
          The Controller is obliged to hand over personal data to state bodies and organizations, in
          accordance with the legally prescribed powers and competencies of those bodies and
          organizations and their officials (for example, judicial bodies, inspection and other
          supervisory and control bodies, etc.).
          The Controller may, as part of international cooperation, transfer personal data from the
          Republic of Serbia, in accordance with the law and other regulations.
        </p>
        <p className="font-medium">Data retention periods</p>
        <p className="font-medium">Article 7</p>
        <p className="py-5 font-medium leading-7">
          Personal data will not be retained longer than is necessary to achieve the purpose for which it
          was collected.
          If the personal data retention period is prescribed by law, the Controller will retain the data
          within the given legal period.
          After the purpose has been fulfilled, i.e. the expiration of the legally prescribed data storage
          period, the data will be permanently deleted.
          In certain cases, personal data may be stored for a longer period, for the purposes of fulfilling
          legal obligations or for the establishment, exercise, or defense of a legal claim, in accordance
          with applicable laws.
        </p>
        <p className="font-medium">Rights of data subjects</p>
        <p className="font-medium">Article 8</p>
        <p className="py-5 font-medium leading-7">
          Data subjects have the following rights:
          <span className="pl-8 block">
            <span className="block">
              1. right to notification of processing and access - data subjects have the right to be informed
              about the processing of their personal data and the right to access their personal data,
              including viewing, reading, listening to data, and making notes;
            </span>
            <span className="block">
              2. the right to receive a copy - data subjects have the right to receive a copy of the data from
              the Controller;
            </span>
            <span className="block">
              3. rights in connection to access - after the performed access, the data subject has the right
              to request from the Controller correction, addition, update, deletion of data, as well as an
              interruption and temporary suspension of personal data processing;

            </span>
            <span className="block">
              4. the right to portability - the data subject may request the Controller to transfer personal
              data to another controller, when it is technically feasible, i.e. when the personal data,
              which is the subject of the transfer request, is in a structured and machine-readable
              format;
            </span>
            <span className="block">
              5. the right to withdraw consent - when the legal basis for processing personal data is the
              consent of the data subject, that data subject has the right to withdraw the given consent
              at any time, in writing;
            </span>
            <span className="block">
              6. the right to object to processing - the data subject has the right to object to the processing
              of personal data for the purpose of direct marketing and to request the restriction of
              processing in some other cases;
            </span>
            <span className="block">
              7. the right to file a complaint with the Commissioner for Information of Public Importance
              and Protection of Personal Data - if the data subject is not satisfied with the Controller's
              response to the request for the fulfillment of rights in terms of protection of personal
              data, he has the right to file a complaint with the Commissioner for Information of
              Public Importance and personal data protection.
            </span>
          </span>
        </p>
        <p className="font-medium">Obligations of employees</p>
        <p className="font-medium">Article 9</p>
        <p className="py-5 font-medium leading-7">
          Employees provide their personal data, which are needed by the Controller to fulfill its legal
          obligations and conduct business, e.g. the performance of activities and the implementation of
          business activities.
          Employees are obliged to respect and protect the personal data they process. Employees may
          process only those data to which they are allowed access, in accordance with the tasks they
          perform.
          The contact person of the Controller who is in charge of personal data protection issues is an
          employee who performs the duties of the CEO of the Controller.
        </p>
        <p className="font-medium">Final provisions</p>
        <p className="font-medium">Article 10</p>
        <p className="py-5 font-medium leading-7">
          This Rulebook shall enter into force on the eighth day from the date of its publication on {' '}
          <a href="https://cinteraction.com/Cinteraction%20-%20Rulebook%20on%20personal%20data
          protection.pdf" target="_blank">https://cinteraction.com/Cinteraction%20-%20Rulebook%20on%20personal%20data
            protection.pdf</a>
        </p>
        <p className="py-5 font-medium leading-7 w-full">
          On behalf of the Controller,
        </p>
        <p className="py-5 font-medium leading-7 w-full">
          Kresimir Kristo
          <span className="block">CEO</span>
        </p>
      </div>
    </div>
  )
}

export default Privacy;
